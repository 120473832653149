










































































import { Component, Vue } from 'vue-property-decorator';
import state from '../State';
import dayjs from 'dayjs';
import {
  FrontendStatistics,
  StatisticsData,
  FullStatistics
} from '../../../types';

@Component({
  name: 'Statistics'
})
export default class Statistics extends Vue {
  model: FrontendStatistics = { statistics: [], search: '' };
  headers = [
    {
      align: 'left',
      text: 'Host',
      value: 'baseUrl'
    },
    {
      align: 'left',
      text: 'Href',
      value: 'location.href'
    },
    {
      align: 'left',
      text: 'Days',
      value: 'days',
      sortable: true
    },
    {
      align: 'left',
      text: 'Total',
      value: 'total',
      sortable: true
    },
    {
      align: 'left',
      text: 'Last',
      value: 'lastDate',
      sortable: true
    },
    {
      name: 'first',
      align: 'left',
      text: 'First',
      value: 'firstDate',
      sortable: true
    },
    {
      align: 'left',
      text: 'Mails',
      value: 'mails',
      sortable: true
    },
    {
      align: 'left',
      text: 'Resp',
      value: 'receivedMail',
      sortable: true
    },
    { text: 'Actions', value: 'actions', sortable: false }
  ];

  created() {
    this.getStatistics();
  }

  setData(data: { statistics: StatisticsData[] } | undefined) {
    if (!data || !data.statistics) return [];
    this.model.statistics = data.statistics.map((stat: FullStatistics) => {
      stat.datesAsArray = Object.keys(stat.location.dates).sort((a, b) =>
        a.localeCompare(b)
      );
      stat.firstDate = stat.datesAsArray[0];
      stat.lastDate = stat.datesAsArray[stat.datesAsArray.length - 1];
      stat.protocol = stat.location.href.split('://')[0];
      stat.baseUrl = stat.protocol + '://' + stat.location.host;
      stat.total = Object.values(stat.location.dates).reduce(
        (next: number, current: number) => next + current,
        0
      );
      stat.days = Object.keys(stat.location.dates).length;
      if (typeof stat.receivedMail === 'undefined') {
        stat.receivedMail = false;
      }
      return stat;
    });
  }

  getStatistics() {
    void fetch(state.get('request.url.statistics'), {
      ...state.get('request.params.api.get')
    })
      .then(res => res.json())
      .then(this.setData);
  }

  remove(item: FullStatistics) {
    const url = state.get('request.url.statistics') as string;
    void fetch(`${url}/${item._id}`, {
      ...state.get('request.params.api.delete'),
      body: JSON.stringify({ _id: item._id })
    })
      .then(res => res.json())
      .then(this.setData);
  }

  mailSent(item: FullStatistics) {
    const url = state.get('request.url.statistics') as string;
    const params = state.get('request.params.api.put');
    if (!item.sentMails) {
      item.sentMails = {
        dates: [dayjs().format('YYYY-MM-DD')],
        count: 1
      };
    } else {
      const date = dayjs().format('YYYY-MM-DD');
      if (!item.sentMails.dates.includes(date)) item.sentMails.dates.push(date);
      ++item.sentMails.count;
    }
    fetch(url + '/' + item._id, {
      ...params,
      body: JSON.stringify({ sentMails: item.sentMails })
    })
      .then(res => res.json())
      .then(data => {
        if (data.ok) this.getStatistics();
      });
  }

  mailReceived(item: FullStatistics) {
    const url = state.get('request.url.statistics');
    const params = state.get('request.params.api.put');
    item.receivedMail = true;
    void fetch(url + '/' + item._id, {
      ...params,
      body: JSON.stringify({ receivedMail: item.receivedMail })
    })
      .then(res => res.json())
      .then(data => {
        if (data.ok) this.getStatistics();
      });
  }
}
