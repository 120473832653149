var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-4"},[_vm._v("las la-chart-line")]),_vm._v("Statistics "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"las la-search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.model.search),callback:function ($$v) {_vm.$set(_vm.model, "search", $$v)},expression:"model.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.model.statistics,"search":_vm.model.search,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'las la-angle-double-left',
      lastIcon: 'las la-angle-double-right',
      prevIcon: 'las la-angle-left',
      nextIcon: 'las la-angle-right'
    }},scopedSlots:_vm._u([{key:"item.baseUrl",fn:function(ref){
    var item = ref.item;
return [_c('a',{staticClass:"light-blue--text d-block text-truncate",attrs:{"href":item.baseUrl,"target":"_blank"}},[_vm._v(_vm._s(item.baseUrl))])]}},{key:"item.mails",fn:function(ref){
    var item = ref.item;
return [(item.sentMails)?_c('span',[_vm._v(" ("+_vm._s(item.sentMails.count)+") "+_vm._s(item.sentMails.dates[item.sentMails.dates.length - 1])+" ")]):_vm._e()]}},{key:"item.receivedMail",fn:function(ref){
    var item = ref.item;
return [(item.receivedMail)?_c('v-icon',[_vm._v("las la-check")]):_vm._e()]}},{key:"item.location.href",fn:function(ref){
    var item = ref.item;
return [_c('a',{staticClass:"light-blue--text d-block text-truncate",staticStyle:{"max-width":"300px"},attrs:{"href":item.location.href,"target":"_blank"}},[_vm._v(_vm._s(item.location.href))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red","elevation":"1","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',[_vm._v("las la-trash")])],1),_c('v-btn',{attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.mailSent(item)}}},[_c('v-icon',[_vm._v("las la-envelope")])],1),_c('v-btn',{attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.mailReceived(item)}}},[_c('v-icon',[_vm._v("las la-envelope-open")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }